<template>
  <div>
    <PatologiesWithTestimonial
      title="Malattia da reflusso gastro-esofageo"
      subtitle1="La malattia da reflusso gastro-esofageo (MRGE) è una patologia molto comune nei paesi altamente sviluppati. Essa è caratterizzata da una serie di sintomi che sono secondari alla risalita del contenuto gastrico in esofago. I principali sintomi sono il bruciore o pirosi retrosternale ed il rigurgito acido (sensazione di risalita di liquido acido dallo stomaco in esofago ed a volte in bocca senza la presenza di nausea e di conati di vomito). Possono essere presenti anche altri sintomi atipici quali il dolore toracico o la sensazione di difficoltoso transito del bolo alimentare (disfagia) o extraesofagei quali difficoltà respiratorie, cardiopalmo etc. Spesso la MRGE si associa alla presenza di un’ernia jatale (risalita parziale dello stomaco dall’addome in torace), che ne è una concausa importante."
      subtitle2="Per un corretto inquadramento diagnostico è opportuna l’esecuzione di una EGDS (gastroscopia), per verificare se presente esofagite, se vi sia un esofago di Barrett ed escludere la presenza di eventuali altre patologie dell’esofago e dello stomaco, di una manometria esofagea, per valutare la motilità dell’esofago e dello sfintere esofageo inferiore (la valvola che separa esofago da stomaco) e di una pH-impedenziometria esofagea 24h che misura per un’intera giornata numero, durata e tipologia di reflussi al fine di poter adeguatamente inquadrare la tipologia di malattia da reflusso presente."
      subtitle3="La maggior parte dei pazienti, oltre che con un’adeguata impostazione dell’alimentazione, possono essere trattati con terapia medica con farmaci chiamati inibitori della pompa protonica che riducono l’acidità del contenuto gastrico e quindi il suo potere lesivo sulla mucosa esofagea. A seconda dei casi tali farmaci possono essere assunti in associazione ad altri farmaci quali gli anti-acidi e i citoprotettori, che creano una barriera protettiva della mucosa. La terapia farmacologica inoltre non sempre necessita di un’assunzione continua, ma spesso, se indicato, può essere assunta con ciclicità o al bisogno. La terapia chirurgica oggigiorno viene effettuata per via mini-invasiva laparoscopica. Consiste nel correggere l’eventuale presenza di ernia jatale, riportando lo stomaco risalito nella sua posizione naturale, e nel confezionare una plastica antireflusso secondo Nissen che si allestisce avvolgendo il fondo dello stomaco attorno allo sfintere esofageo inferiore per restituirgli una maggiore competenza. Generalmente i pazienti che presentano sintomi tipici da reflusso (pirosi e rigurgito) che si risolvono con terapia farmacologica, sono anche quelli che hanno i migliori risultati con la chirurgia. L’intervento chirurgico richiede una degenza di 2-3 giorni ed il paziente dopo una decina di giorni di dieta con cibi morbidi può riprendere una dieta libera."
      cta1="Costituisce il primo momento di ricognizione e valutazione della storia clinica, dei sintomi e dello stato di salute. Il medico specialista prende visione degli esami diagnostici effettuati; per questo motivo le consigliamo di portare con sé almeno una TC del torace e dell’addome con mezzo di contrasto ed un’esofagogastroscopia recente, corredata di biopsia ed indagine istologica."
      cta2="Il medico potrebbe richiederle alcuni approfondimenti diagnostici utili alla scelta terapeutica più efficace e personalizzata. Potrebbero completare il quadro strumentale alcuni esami, fra cui lo studio radiologico delle prime vie digestive, la manometria esofagea, la pH-impedenziometria esofagea delle 24 ore e la pH-metria esofagea delle 24 ore."
      cta3="Le opportunità di trattamento possono variare da terapie mediche, mediante la sola assunzione di farmaci, a trattamenti endoscopici, fino ad intervento chirurgico con la preferenza per approcci di chirurgia mini-invasiva. La scelta migliore sarà discussa e condivisa con lei."
      cta4="Al termine della maggior parte dei trattamenti terapeutici proposti si rende utile seguire un piano di controlli diagnostici periodici, concordati con il medico specialista. Questi saranno valutati durante la visita con lo specialista e verranno suggeriti i successivi."
    />
    <TestimonialPatologySection
      subject="Andrea Cappelletti"
      patology="myreview"
      :image="localImage1"
      testimonial="Sono un ragazzo di 21 anni, sin dall'adolescenza soffrivo di reflusso gastroesofageo..."
    />
    <CtaContactSection />
    <FooterSection />
  </div>
</template>

<script>
import PatologiesWithTestimonial from "../components/PatologiesWithTestimonial.vue";
import TestimonialPatologySection from "../components/TestimonialPatologySection.vue";
import testimonialImage1 from "@/assets/testimonial/AndreaRiccardo.jpg";
import FooterSection from "../components/FooterSection.vue";
import CtaContactSection from "../components/CtaContactSection.vue";

export default {
  name: "Reflusso",
  components: {
    PatologiesWithTestimonial,
    TestimonialPatologySection,
    FooterSection,
    CtaContactSection,
  },
  data() {
    return {
      localImage1: testimonialImage1,
    };
  },
};
</script>
